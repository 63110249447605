import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Trans from 'next-translate/Trans';
import useCmsTranslation from '@hooks/useCmsTranslation';
import { ReactComponent as ChatIcon } from '@ui/assets/icons/chat.svg';
import { centerContent } from '@ui/styles/base';
import Box from '@ui-v2/core/Box/Box';
import LinkButton from '@ui-v2/core/Button/LinkButton';
import Link from '@ui-v2/core/Link/Link';
import Text from '@ui-v2/core/Text/Text';
import { createTypography } from '@ui-v2/utils/styleUtils';
import usePartnerInfo from '@web/context/hooks/usePartnerInfo';
import SEO from '../SEO/SEO';

interface Props {
  callToActionHref?: string;
  callToActionText?: string;
  hideAction?: boolean;
  subtitle: string;
  title: string;
}

const Container = styled.div(({ theme: { colours, typography } }) => [
  createTypography(typography.body01),
  centerContent,
  css`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${colours.background.main};
  `,
]);

const Content = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 ${spacings['16']}px;
    margin: auto;
  `,
);

const TopContainer = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacings['16']}px;
  `,
);

const BottomContainer = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    width: 70%;
    flex-direction: column;
    align-self: center;
    margin-top: ${spacings['24']}px;
  `,
);

const TitleContainer = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
);

const BoldDiv = styled.div`
  font-weight: bold;
`;

const StyledChatIcon = styled(ChatIcon)(
  ({ theme: { spacings } }) => css`
    width: 15px;
    height: 15px;
    margin-right: ${spacings['16']}px;
  `,
);

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
`;

const StyledContent = styled.div(
  ({ theme: { spacings } }) => css`
    padding-left: calc(15px + ${spacings['16']}px);
  `,
);

const ErrorPage = ({
  callToActionHref,
  callToActionText,
  hideAction,
  subtitle,
  title,
}: Props) => {
  const { t } = useCmsTranslation();
  const {
    helpCenter: { email, supportLink },
  } = usePartnerInfo();

  return (
    <>
      <SEO hreflangs={[]} isIndexed={false} title={title} />
      <Container>
        <Content>
          <TopContainer>
            <TitleContainer>
              <Text as="h1" mb={8} textAlign="center" variant="heading-1">
                <div>{t('Oops!', 'Oops!')}</div>
                <div>{title}</div>
              </Text>
            </TitleContainer>
            <Text as="p" textAlign="center">
              {subtitle}
            </Text>
            {!hideAction && (
              <Box display="flex" justifyContent="center" mt={24}>
                <LinkButton href={callToActionHref || '/'} target="self">
                  {callToActionText || t('Front page', 'Front page')}
                </LinkButton>
              </Box>
            )}
          </TopContainer>
          <BottomContainer>
            <StyledHeading>
              <StyledChatIcon />
              <BoldDiv>
                {t(
                  'How to get in touch with us?',
                  'How to get in touch with us?',
                )}
              </BoldDiv>
            </StyledHeading>
            <StyledContent>
              <Trans
                components={[
                  <Link href={supportLink} key={supportLink} target="_blank">
                    {t(
                      'Please visit our support page',
                      'Please visit our support page',
                    )}
                  </Link>,
                  <Link href={`mailto: ${email}`} key="service-email-link">
                    {email}
                  </Link>,
                ]}
                i18nKey="Please visit our support page"
                values={{
                  email,
                }}
              />
            </StyledContent>
          </BottomContainer>
        </Content>
      </Container>
    </>
  );
};

export default ErrorPage;
